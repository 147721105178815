import { render, staticRenderFns } from "./ImageSpinner.vue?vue&type=template&id=e745b496&scoped=true&"
var script = {}
import style0 from "./ImageSpinner.vue?vue&type=style&index=0&id=e745b496&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e745b496",
  null
  
)

export default component.exports