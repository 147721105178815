<template>
  <!-- scroll area -->
  <section
    class="image-uploader--field h-full overflow-auto mt-4 sm:p-8 w-full h-full flex flex-col"
    :style="{
      border: '1px solid #cecece',
      borderRadius: '4px'
    }"
  >
    <b-upload
      v-model="file"
      multiple
      drag-drop
      accept="image/png, image/jpeg, image/jpg"
      class="border-dashed border-2 border-gray-400 flex flex-col justify-center items-center"
    >
      <section class="section">
        <div class="content has-text-centered">
          <p>
            <b-icon icon="upload" size="is-large" />
          </p>
          <p>
            Drop your files here or click to upload. Drop a PNG, JPG, or JPEG file here, or click to
            select a file from your computer.
          </p>
        </div>
      </section>
    </b-upload>
    <ul id="gallery" class="flex flex-1 flex-wrap" :style="{ marginTop: '20px' }">
      <li
        v-show="!previews.length"
        class="h-full w-full text-center flex flex-col items-center justify-center items-center"
      >
        <img
          class="mx-auto w-32"
          src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
          alt="no data"
        />
        <span class="text-small text-gray-500">No images added</span>
      </li>
      <li v-for="(image, index) in previews" :key="index" class="p-1 flex w-32 h-32">
        <div
          class="align-items-center relative w-full h-full bg-cover"
          :style="`background-image: url(${image});`"
        >
          <button class="delete is-small" type="button" @click="deleteDropFile(index)" />
          <label>
            <span style="width: 100px"></span>
          </label>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
/*
  import ImageUploader from '@/components/Fields/ImageUploader'

  <ImageUploader
    :onChange="imagesUpdated"
  />
 */
//
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'
//
export default {
  props: {
    onChange: Function,
    label: {
      type: String,
      default: 'Image Uploader'
    }
  },
  data,
  methods,
  watch
}
</script>

<style lang="scss">
// this class needs to stay unique
.image-uploader--field {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}
</style>
