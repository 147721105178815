<template>
  <div class="tile is-parent is-12">
    <article
      class="card"
      :style="{
        padding: '1rem',
        width: '100%'
      }"
    >
      <div class="level">
        <span class="title level-left">History</span>
      </div>

      <div
        vio-step
        v-for="(step, index) in steps"
        :key="index"
        class="card"
        :style="{
          position: 'relative',
          padding: '1rem',
          boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)',
          marginBottom: '12px'
        }"
      >
        <div
          vio-step-date
          :style="{
            position: 'absolute',
            top: '12px',
            right: '12px',
            color: color[8]
          }"
        >
          {{ step.observationDate }}
        </div>

        <b-tag size="is-large" vio-step-count>Step {{ step.count }}</b-tag>
        <b-tag size="is-large" :style="{ marginLeft: '12px' }" vio-step-name>{{
          step.actionName
        }}</b-tag>

        <div :style="{ padding: '20px 0 20px 10px' }">
          <div class="columns" :style="{ display: 'flex' }">
            <div class="column is-2" :style="{ color: color[8] }">Observed by:</div>
            <div class="column is-9" :style="{ color: color[8], fontWeight: 'bold' }">
              {{ step.observedBy }}
            </div>
          </div>

          <div class="columns" :style="{ display: 'flex' }">
            <div class="column is-2" :style="{ color: color[8] }">Description:</div>
            <div
              v-if="step.description.length <= 200"
              class="column is-9"
              :style="{ color: color[8], fontWeight: 'bold' }"
            >
              {{ step.description }}
            </div>
            <div
              v-if="step.description.length > 200"
              class="column is-9"
              :style="{ color: color[8], fontWeight: 'bold' }"
            >
              {{ step.description.slice(1, 200)
              }}<span class="pt-5 pb-3 pl-3" v-if="step.description.length > 200">
                <b-button
                  style="height: 20px;"
                  title="click to expand/collapse the description"
                  class="p-2 font-bold button is-small is-outline is-rounded"
                  @click.prevent="handleEllipsis()"
                  >{{ `${showEllipsis === false ? 'Read More...' : 'Read Less...'}` }}</b-button
                > </span
              ><span
                v-if="step.description.length > 200 && showEllipsis === true"
                class="column is-9 pl-2"
                :style="{ color: color[8], fontWeight: 'bold' }"
                >{{ step.description.slice(200, step.description.length) }}</span
              >
            </div>
          </div>
        </div>

        <div
          :style="{
            position: 'relative',
            width: '100%',
            height: '80%',
            paddingRight: '10px',
            paddingLeft: '6px'
          }"
          class="property-photo-gallery"
        >
          <figure
            vio-step-photo
            v-for="(photo, index) in step.photos"
            :key="index"
            tabindex="0"
            :aria-label="'photo ' + (index + 1)"
            :style="{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '4px',
              height: '140px',
              width: '140px',
              overflow: 'hidden',
              borderRadius: '6px',
              boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
            }"
            @click.prevent="openPhotoModal(photo)"
          >
            <transition name="fade">
              <img
                :src="photo.content"
                :style="{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                  flexShrink: '0',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }"
              />
            </transition>
          </figure>
        </div>
        <div v-if="step && step.violationLetters && step.violationLetters.length > 0">
          <label class="label">Violation Letter(s)</label>
        </div>
        <div
          v-for="(letter, index) in step.violationLetters"
          :key="index"
          class="card"
          aria-label="Violation Letters"
          :style="{
            position: 'relative',
            padding: '1rem',
            boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)',
            marginBottom: '12px'
          }"
        >
          <button
            type="button"
            @click.prevent="getDocumentFile(letter.violationStepDocumentID)"
            class="button is-link is-light"
          >
            {{ `View Letter` }}
          </button>
        </div>
        <b-modal v-model="photoModal.open" :style="{ zIndex: 999 }">
          <p class="image is-4by3">
            <img :src="photoModal.src" />
          </p>
        </b-modal>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ViolationHistory',

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { violationConversations: { title: 'Conversations' } }
    }
  }
}
</script>

<style></style>
