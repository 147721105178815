/*
  import { update as updateV } from '@/services/ViolationIssue/Violation/UpdateViolationActionStepOccurrence';

  // for example:
  const { successful, message } = await updateV ({
    json: {

    }
  });
  if (!successful) {
    notifyProblem (message);
    return;
  }
*/

//
import kms from '@/services/kms'
//
export async function update({ kms: kms_ = kms, json } = {}) {
  let message = `The update did not happen successfully.`

  try {
    await kms_.post(`/ViolationIssue/Violation/UpdateViolationActionStepOccurrence`, json)

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
