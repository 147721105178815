/*
  import { violationListStore } from '@/pages/Management/violations/Main/store.js'

  this.unsubViolationListStore = violationListStore.subscribe ((mutation, state) => {
    const mType = mutation.type;


  });
*/

import _set from 'lodash/set'
import Vue from 'vue'
import Vuex from 'vuex'
//
import { notifyProblem } from '@/services/notify'
//
import { listViolations } from '@/services/ViolationIssue/Violation/List'
//
Vue.use(Vuex)
export const violationListStore = new Vuex.Store({
  /* eslint-disable no-empty-pattern */
  actions: {
    /*
      const { list } = await violationListStore.dispatch ('refreshList', {
        hoaID
      });
    */
    async refreshList({ commit }, { hoaID }) {
      const { successful, list, message } = await listViolations({
        hoaID
      })
      if (!successful) {
        notifyProblem(message)
      }

      commit('refreshList', { list, hoaID })

      return {
        list
      }
    }
  },
  /* eslint-enable no-empty-pattern */

  getters: {},
  mutations: {
    refreshList(state, { list, hoaID }) {
      _set(state, ['lists', hoaID], list)
    }
  },

  /*

  */
  state: {
    /*
      lists [ hoaId ] = []

      e.g.
        lists = {
          0: []
        }
    */
    lists: {}
  }
})
