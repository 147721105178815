var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"card",style:({ padding: '.5rem' })},[_c('div',{style:({ color: _vm.color[8] })},[_vm._v("All Photos")]),(_vm.photosList)?_c('div',{style:({
      overflowY: 'scroll',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      width: '100%',
      paddingBottom: '10px'
    })},_vm._l((_vm.photosList),function(photo,index){return _c('figure',{key:index,style:({
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '4px',
        width: '95px',
        height: '71px',
        overflow: 'hidden',
        borderRadius: '6px',
        boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
      }),attrs:{"vio-photo":"","tabindex":"0","aria-label":'photo ' + (index + 1)},on:{"click":function($event){$event.preventDefault();return _vm.updateMainPhoto(index)}}},[_c('transition',{attrs:{"name":"fade"}},[_c('ImageItem',{attrs:{"source":photo.content}})],1)],1)}),0):_vm._e(),(_vm.loading)?_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }