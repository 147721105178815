import _get from 'lodash/get'

export function data() {
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)

  return {
    authUserType,
    color: theme.color,
    toggle: false,
    loading: true,
    mainPhoto: null,
    occurrence: null,
    violation: {},
    occurrences: [],
    steps: [],
    photosList: [],
    details: {},
    startIndex: 1,
    itemsPerPage: 1,
    hoaID: 0
  }
}
