/*
  example:
    import { getPhoto } from '@/services/ViolationIssue/ViolationOccurrencePhoto/GetFile';

    const { photo, successful, message } = await getPhoto ({
      params: {}
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getPhoto({ params, kms: kms_ = kms } = {}) {
  let message = `A photo could not be retrieved.`

  try {
    const result = await kms_.get(`/ViolationIssue/ViolationOccurrencePhoto/GetFile`, {
      params
    })

    return {
      successful: true,
      message: '',
      photo: result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    photo: null
  }
}

///////
