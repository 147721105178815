//
import { notifyProblem } from '@/services/notify'
//
export const methods = {
  async deleteDropFile(index) {
    this.file.splice(index, 1)
    this.previews.splice(index, 1)

    this.onChange({
      previews: this.previews,
      images: this.file
    })
  },

  async updatePictures() {
    const images = this.file
    if (!Array.isArray(images)) {
      notifyProblem('A problem occurred and the photos could not be updated.')
      return
    }

    ////////////////////////////////
    //  Store image previews
    ////////////////////////////////

    this.previews = this.file.map(file => {
      return URL.createObjectURL(file)
    })

    this.onChange({
      previews: this.previews,
      images: this.file
    })
  }
}
