import _get from 'lodash/get'

export function data() {
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  return {
    color: theme.color,
    isDebug: true,
    loading: false,
    hoaID: 0,
    violationID: 0,
    mainPhoto: null,
    showEllipsis: false,
    occurrences: [],
    steps: [],
    photosList: [],
    photoModal: {
      open: false,
      src: ''
    },
    styles: {
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    }
  }
}
