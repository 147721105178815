/*
  example:
    import { getVOccurrenceList } from '@/services/ViolationIssue/ViolationOccurrence/List';

    const { list, successful, message } = await getVOccurrenceList ({
      json: {
        hoaID,
        violationID
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
import _get from 'lodash/get'
//
export async function getVOccurrenceList({ json, kms: kms_ = kms } = {}) {
  let message = `The occurrence list could not be retrieved.`

  try {
    const result = await kms_.get(`/ViolationIssue/ViolationOccurrence/List`, {
      params: json
    })

    console.log({
      result
    })

    return {
      successful: true,
      message: '',
      list: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: null
  }
}

///////
