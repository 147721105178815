export const props = {
  //
  // for testing, so that the id
  // can be passed in as a prop
  // instead of being retrieved
  // from the route
  //
  propId: {
    type: [Number, Boolean],
    default: false
  }
}
