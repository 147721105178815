/*
  example:
    import { getVActionList } from '@/services/Violation/ViolationAction/List';

    const { list, successful, message } = await getVActionList ({
      hoaID,

      // optional
      escalationID
    });
    if (!successful) {
      console.error (exception);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function getVActionList({ hoaID, escalationID = null, kms: kms_ = kms } = {}) {
  let message = `The action list could not be retrieved.`

  console.log('\n\ngetVActionList\n')

  let params = {
    hoaID
  }

  if (['string', 'number'].includes(typeof escalationID)) {
    params.violationEscalationID = escalationID
  } else {
    console.error(`/Violation/ViolationAction/List`, { escalationID })
  }

  try {
    const result = await kms_.get(`/Violation/ViolationAction/List`, {
      params
    })

    let list = []
    const actions = _get(result, 'results', [])
    if (Array.isArray(actions)) {
      list = actions
    }

    return {
      successful: true,
      message: '',
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: null
  }
}

///////
