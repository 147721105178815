/*
  import { updateV } from '@/services/ViolationIssue/Violation/Put';

  // for example:
    const { successful, message, details } = await updateV ({
      json: {}
    });
    if (!successful) {
      notifyProblem (message);
      return;
    }

*/
//
import kms from '@/services/kms'
//
export async function updateV({ kms: kms_ = kms, json } = {}) {
  try {
    const details = await kms_.put(`/ViolationIssue/Violation`, json)

    return {
      successful: true,
      message: null,
      details
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message: `The details could not be retrieved successfully.`,
    details: null
  }
}
