var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"v-escalator","vio-escalator":""}},[_c('Modal',{attrs:{"toggle":_vm.isOpen,"styles":{
      modalContent: { height: '600px' },
      modal: { maxHeight: '90vh' }
    },"loading":_vm.loading},on:{"update:toggle":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Escalate Violation")]},proxy:true}])},[_c('b-steps',{attrs:{"size":"is-small","has-navigation":false},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('b-step-item',{attrs:{"label":"Action Select","ve-action-select":""}},[_c('div',{style:({
            display: 'flex',
            padding: '3px',
            alignItems: 'center'
          })},[_c('div',{style:({
              display: 'inline-block',
              marginRight: '12px',
              flexBasis: '200px',
              flexGrow: 0,
              flexShrink: 0
            })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v("Owner")])],1),_c('div',[_vm._v(_vm._s(_vm.details.ownerName))])]),_c('div',{style:({
            display: 'flex',
            padding: '3px',
            alignItems: 'center'
          })},[_c('div',{style:({
              display: 'inline-block',
              marginRight: '12px',
              flexBasis: '200px',
              flexGrow: 0,
              flexShrink: 0
            })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v("Address")])],1),_c('div',[_vm._v(_vm._s(_vm.details.ownerAddress))])]),_c('div',{style:({
            display: 'flex',
            padding: '3px',
            alignItems: 'center'
          })},[_c('div',{style:({
              display: 'inline-block',
              marginRight: '12px',
              flexBasis: '200px',
              flexGrow: 0,
              flexShrink: 0
            })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v("Type")])],1),_c('div',[_vm._v(_vm._s(_vm.details.type))])]),_c('div',{style:({ borderTop: ("1px solid " + (_vm.color[9])) })},[_c('div',{style:({
              display: 'flex',
              padding: '3px',
              alignItems: 'center'
            })},[_c('div',{style:({
                display: 'inline-block',
                marginRight: '12px',
                flexBasis: '200px',
                flexGrow: 0,
                flexShrink: 0
              })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v("Steps Taken")])],1)]),_vm._l((_vm.details.steps),function(step,index){return _c('div',{key:index,staticClass:"card rounded shadow bg-white",style:({
              position: 'relative',
              marginBottom: '10px',
              padding: '12px',
              border: '1px solid #f4f1f1'
            })},[_c('div',[_c('div',[_vm._v("Action: "+_vm._s(step.actionName))]),_c('div',[_vm._v("Action Description: "+_vm._s(step.actionDescription))]),_c('div',[_vm._v("Observed By: "+_vm._s(step.observedBy))]),_c('div',[_vm._v("Observation Description: "+_vm._s(step.observationDescription))])]),_c('div',[_vm._v("Observation Date: "+_vm._s(step.observationDate))])])})],2),_c('div',{style:({ borderTop: ("1px solid " + (_vm.color[9])) })}),_c('div',{style:({
            display: 'flex',
            padding: '3px',
            alignItems: 'center'
          })},[_c('div',{style:({
              display: 'inline-block',
              marginRight: '12px',
              flexBasis: '200px',
              flexGrow: 0,
              flexShrink: 0
            })},[_c('b-tag',{style:({ fontWeight: 'bold' })},[_vm._v("Next Step")])],1)]),_vm._l((_vm.allActions),function(action,index){return _c('div',{key:index,staticClass:"card rounded shadow bg-white",style:({
            display: 'flex',
            justifyItems: 'right',
            justifyContent: 'space-between',

            marginBottom: '10px',
            border: '1px solid #f4f1f1',
            padding: '12px'
          })},[_c('p',{style:({ padding: '8px 0' })},[_vm._v(_vm._s(action.description))]),_c('button',{staticClass:"button is-primary is-rounded",attrs:{"ve-action-button":""},on:{"click":function($event){return _vm.select(action)}}},[_vm._v(" "+_vm._s(action.name)+" ")])])})],2),_c('b-step-item',{attrs:{"label":"Details","ve-details":""}},[_c('div',{style:({ width: '70%', margin: '0 auto 50px' })},[_c('b-field',{attrs:{"label":"Observed By"}},[_c('b-input',{attrs:{"ve-observed-by":"","maxlength":"255","aria-required":"true","type":"text"},model:{value:(_vm.observedBy),callback:function ($$v) {_vm.observedBy=$$v},expression:"observedBy"}})],1),_c('b-field',{attrs:{"label":"Observed On"}},[_c('DatePicker',{ref:"observationDateComponent",attrs:{"ve-observation-date":"","ariaLabel":"date"},model:{value:(_vm.observationDate),callback:function ($$v) {_vm.observationDate=$$v},expression:"observationDate"}})],1),_c('b-field',{style:({ marginTop: '20px' }),attrs:{"label":"Description"}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","ve-description":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-field',{style:({ marginTop: '20px' }),attrs:{"label":"Pictures (optional)"}},[_c('ImageUploader',{attrs:{"onChange":_vm.imagesUpdated,"ve-image-uploader":""}})],1),_c('div',{style:({
              padding: '30px 0',
              textAlign: 'right'
            })},[_c('b-button',{attrs:{"type":"is-primary","size":"is-medium","ve-details-next-button":""},on:{"click":_vm.checkDetails}},[_vm._v("Next")])],1)],1)]),_c('b-step-item',{attrs:{"label":"Confirmation","ve-confirmation":""}},[_c('div',{style:({ width: '70%', margin: '0 auto' })},[_c('p',{style:({ fontSize: '1.4rem' })},[_vm._v(" To proceed, please review the details and then press "),_c('b',[_vm._v("Confirm")]),_vm._v(". ")]),_c('div',{style:({ padding: '30px 0' })},[_c('div',{style:({
                borderTop: ("1px solid " + (_vm.color[9])),
                margin: '5px 0'
              })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Action")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.selected.name))])]),_c('div',{style:({
                borderTop: ("1px solid " + (_vm.color[9])),
                margin: '5px 0'
              })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Owner")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.ownerName))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Address")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.ownerAddress))])]),_c('div',{style:({
                borderTop: ("1px solid " + (_vm.color[9])),
                margin: '5px 0'
              })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Observed By")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.observedBy))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Observation Date")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.observationDateParsed))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Description")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.description))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Pictures")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},_vm._l((_vm.previews),function(preview,index){return _c('figure',{key:index,style:({
                    position: 'relative',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    margin: '4px',

                    width: '70px',
                    height: '70px',

                    overflow: 'hidden',
                    borderRadius: '6px',

                    boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
                  }),attrs:{"tabindex":"0","aria-label":'photo ' + (index + 1)}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{style:({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(-50%)',

                        flexShrink: '0',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }),attrs:{"src":preview}})])],1)}),0)])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"end"}},[_c('b-button',{style:({ marginRight: '20px' }),attrs:{"type":"is-primary","size":"is-medium"},on:{"click":_vm.preview}},[_vm._v("Preview")]),_c('b-button',{style:({ float: 'right' }),attrs:{"ve-confirm-button":"","type":"is-primary","size":"is-medium"},on:{"click":_vm.confirm}},[_vm._v("Confirm")])],1),_c('div',{style:({ height: '70px' })})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }