import _get from 'lodash/get'
import moment from 'moment'
import parseAddress from '@/utilities/address/parse'
import { parseDate } from '@/utilities/Date/parse'
import { notifyProblem, notifyMessage } from '@/services/notify'
import { getVActionList } from '@/services/Violation/ViolationAction/List'
import { getV } from '@/services/ViolationIssue/Violation/Get'
import { update as updateV } from '@/services/ViolationIssue/Violation/UpdateViolationActionStepOccurrence'
import { StoreMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/StoreMultiplePhotos'
import { generateViolationLetterHTMLPreview } from '@/services/ViolationIssue/Violation/GenerateViolationLetterHTMLPreview'
import { violationListStore } from '@/pages/Management/violations/Main/store.js'

export const methods = {
  updateObservedBy() {
    const authUser = _get(this, ['$store', 'getters', 'user/authUser'], null)

    this.observedBy = authUser.name
  },
  imagesUpdated({ previews, images }) {
    this.images = images
    this.previews = previews
  },
  checkDetails() {
    if (this.description.length === 0) {
      notifyProblem('Please provide a description.')
      return
    }

    if (this.observedBy.length === 0) {
      notifyProblem('Please state who is reporting the action.')
      return
    }

    const { allValid, utcFormat } = this.$refs.observationDateComponent.getValue()
    if (allValid !== true) {
      notifyProblem('The date does not seem to be valid.')
      return
    }

    this.observationDate = utcFormat

    this.page = 2
  },

  reset() {
    // resets
    this.description = ''
    this.observedBy = ''
    this.observationDate = moment(new Date()).toISOString()
    this.images = []
    this.previews = []

    this.allActions = []
    this.selected = {}
  },

  async open({ id }) {
    this.loading = true
    this.page = 0

    this.reset()

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    this.updateObservedBy()
    this.isOpen = true

    const { successful, message, details } = await getV({
      id
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    const {
      list: allActions,
      successful: actionSuccessful,
      message: actionMessage
    } = await getVActionList({
      hoaID,

      // optional
      escalationID: _get(details, ['violationEscalationID'], '')
    })
    if (!actionSuccessful) {
      notifyProblem(actionMessage)
      return
    }

    let chosenActions = _get(details, ['violationEscalation', 'violationActions'], [])
    if (!Array.isArray(chosenActions)) {
      chosenActions = []
    }

    this.allActions = allActions

    let actions = []
    for (let a = 0; a < allActions.length; a++) {
      const possible = allActions[a]

      for (let b = 0; b < chosenActions.length; b++) {
        const chosen = chosenActions[b]

        if (chosen.violationActionID === possible.violationActionID) {
          actions.push({
            name: possible.name,
            order: possible.escalationOrder
          })
          break
        }
      }
    }

    var steps = _get(details, ['violationSteps'], [])
    if (!Array.isArray(steps)) {
      steps = []
    }

    this.details = {
      ownerName: _get(details, ['owner', 'name'], ''),
      ownerAddress: parseAddress({
        address: _get(details, ['owner', 'unit', 'address'], '')
      }),
      type: _get(details, ['violationType', 'name'], ''),
      actions: chosenActions,
      steps: steps.map(step => {
        return {
          actionName: _get(step, ['violationAction', 'name'], ''),
          actionDescription: _get(step, ['violationAction', 'description'], ''),
          observedBy: _get(step, ['violationOccurrence', 'observedBy'], ''),
          observationDescription: _get(step, ['violationOccurrence', 'description'], ''),
          observationDate: parseDate(
            _get(step, ['violationOccurrence', 'observationDate'], ''),
            'LL'
          )
        }
      })
    }

    this.meta = details
    this.description = _get(details, ['violationType', 'descriptionTemplate'])

    this.loading = false
  },
  async select(action) {
    this.selected = {
      name: action.name,
      description: action.description,
      violationActionID: action.violationActionID,
      violationEscalationID: action.violationEscalationID
    }

    this.page = 1
  },

  async uploadImages() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { uploads, problems } = await StoreMultiplePhotos({
      hoaID,
      photos: this.images
    })

    if (problems.length >= 1) {
      notifyProblem(`${problems.length} photos may not have been uploaded successfully.`)
    }

    if (problems && problems !== undefined) {
      console.debug('problems=' + JSON.stringify(problems))
    }

    return {
      uploads
    }
  },

  async confirm() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    this.loading = true

    const { uploads } = await this.uploadImages()

    // for example:
    const { successful, message } = await updateV({
      json: {
        hoaID,
        ownerID: _get(this, ['meta', 'owner', 'ownerID'], ''),
        violationID: _get(this, ['meta', 'violationID'], ''),
        //
        violationTypeID: _get(this, ['meta', 'violationType', 'violationTypeID'], ''),
        violationEscalationID: _get(
          this,
          ['meta', 'violationEscalation', 'violationEscalationID'],
          ''
        ),
        violationActionID: _get(this, ['selected', 'violationActionID']),
        //
        violationNote: '',
        isClosed: false,
        //
        observationDate: this.observationDate,
        observedBy: this.observedBy,
        observationDescription: this.description,
        //
        uploadedDocumentIds: uploads
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    notifyMessage('The step was initiated successfully.')

    // refreshes the main list
    await violationListStore.dispatch('refreshList', {
      hoaID
    })

    await this.onComplete()

    this.loading = false
    this.isOpen = false
  },

  async preview() {
    this.loading = true

    const { uploads } = await this.uploadImages()

    // for example:
    const { message, result, successful } = await generateViolationLetterHTMLPreview({
      json: {
        ownerID: _get(this, ['meta', 'owner', 'ownerID'], ''),
        //
        violationTypeID: _get(this, ['meta', 'violationType', 'violationTypeID'], ''),
        violationActionID: _get(this, ['selected', 'violationActionID']),
        //
        violationNote: '',
        isClosed: false,
        //
        observationDate: this.observationDate,
        description: this.description,
        //
        photoDocumentIDs: uploads
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    this.loading = false

    var newWindow = window.open()
    if (!newWindow.document) {
      notifyProblem('Unable to preview letter - please make sure popups are not blocked.')
      return
    }

    newWindow.document.body.innerHTML = result
  }
}
