<template>
  <article class="card" :style="{ padding: '.5rem', height: '420px' }">
    <div :style="{ color: color[8] }">All Photos</div>
    <div
      v-if="photosList && !loading"
      :style="{
        maxHeight: '350px',
        minHeight: '350px',
        overflowY: 'scroll'
      }"
    >
      <figure
        vio-photo
        v-for="(photo, index) in photosList"
        :key="index"
        tabindex="0"
        :aria-label="'photo ' + (index + 1)"
        :style="{
          position: 'relative',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',

          margin: '4px',

          width: '90%',
          paddingBottom: '90%',

          overflow: 'hidden',
          borderRadius: '6px',

          boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
        }"
        @click.prevent="updateMainPhoto(index)"
      >
        <transition name="fade">
          <ImageItem :source="photo.content" />
        </transition>
      </figure>
    </div>
    <b-loading :is-full-page="false" v-model="loading" v-if="loading"></b-loading>
  </article>
</template>

<script>
import ImageItem from '@/components/LazyLoad/ImageItem'

export default {
  props: {
    loading: Boolean,
    updateMainPhoto: Function,
    photosList: Array,
    color: Object
  },
  components: {
    ImageItem
  }
}
</script>
