import _get from 'lodash/get'
import moment from 'moment'

export function data() {
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  return {
    color: theme.color,
    isOpen: false,
    details: {
      steps: []
    },
    allActions: [],
    page: 0,
    loading: true,
    selected: {},
    description: '',
    observedBy: '',
    observationDate: moment(new Date()).toISOString(),
    images: [],
    previews: [],
    styles: {
      listRow: {
        display: 'flex'
      },
      listLabel: {
        display: 'inline-block',
        flexBasis: '186px',
        color: 'rgb(130, 130, 130)'
      },
      listItem: {
        display: 'inline-block',
        flexBasis: `calc(100% - 186px)`
      }
    }
  }
}
