/*
  For generating a violation preview letter

  {
    "ownerID": 0,
    "violationActionID": 0,
    "violationTypeID": 0,
    "observationDate": "2021-08-30T18:40:19.581Z",
    "description": "string",
    "photoDocumentIDs": [
      0
    ]
  }
*/

/*
  import { generateViolationLetterHTMLPreview } from '@/services/ViolationIssue/Violation/GenerateViolationLetterHTMLPreview';

  // for example:
    const { successful } = await generateViolationLetterHTMLPreview ({
      json: {
        ownerID,
        violationTypeID,
        violationActionID,
        description: "vio description",
        observationDate: "2021-08-09T21:31:51.958Z",
        photoDocumentIDs: []
      }
    });
    if (!successful) {
      notifyProblem ('A problem occurred and the violation letter could not be generated');
      return;
    }
*/

//
import kms from '@/services/kms'
//
export async function generateViolationLetterHTMLPreview({ kms: kms_ = kms, json } = {}) {
  const message = 'A problem occurred and the violation could not be reported'

  try {
    const result = await kms_.post(
      `/ViolationIssue/Violation/GenerateViolationLetterHTMLPreview`,
      json
    )

    return {
      message: '',
      result,
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    message,
    result: null,
    successful: false
  }
}
