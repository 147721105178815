/*
  import { StoreMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/StoreMultiplePhotos';

  const { successful, documentIds } = await StoreMultiplePhotos ({
    hoaID,

    // Array of Files
    photos: []
  });
  if (!successful) {

  }

*/

import { StorePhoto } from '@/services/ViolationIssue/ViolationOccurrencePhoto/StorePhoto'

export async function StoreMultiplePhotos({ hoaID, photos }) {
  const results = await Promise.all(
    photos.map(photo => {
      return StorePhoto({
        hoaID,
        photo
      })
    })
  )

  const problems = []
  const uploads = []
  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful, documentId } = result
    if (successful === false) {
      problems.push(result.photo)
    }

    if (successful === true) {
      uploads.push(documentId)
    }
  }

  return {
    uploads,
    problems
  }
}
