var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{directives:[{name:"lazyload",rawName:"v-lazyload"}],staticClass:"image__wrapper"},[_c('ImageSpinner',{staticClass:"image__spinner"}),_c('img',{staticClass:"image__item",style:({
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      flexShrink: '0',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }),attrs:{"data-url":_vm.source}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }