var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{style:({
    display: 'flex'
  }),attrs:{"vio-details":""}},[(_vm.breakpoint === 'desktop')?_c('div',{style:({
      width: '20%',
      marginRight: '1%'
    })},[_c('DesktopGallery',{attrs:{"color":_vm.color,"loading":_vm.loading,"updateMainPhoto":_vm.updateMainPhoto,"photosList":_vm.photosList}})],1):_vm._e(),_c('div',{staticClass:"card",style:(Object.assign({}, {display: 'flex',
      width: '100%',
      padding: '1rem'},

      (_vm.breakpoint === 'desktop'
        ? {}
        : {
            flexDirection: 'column'
          })))},[_c('article',{style:({ textAlign: 'center' })},[_c('figure',{style:({
          position: 'relative',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',

          margin: '4px',

          height: '380px',
          width: '380px',
          maxWidth: '100%',

          overflow: 'hidden',
          borderRadius: '6px',

          boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
        }),attrs:{"tabindex":"0","aria-label":"main photo"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.mainPhoto && _vm.mainPhoto.length > 0)?_c('img',{style:({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',

              flexShrink: '0',
              maxWidth: '100%',
              maxHeight: '100%',
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }),attrs:{"vio-main-photo":"","src":_vm.mainPhoto}}):_vm._e()]),(_vm.mainPhoto && _vm.mainPhoto.length === 0)?_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" No photos were added ")]):_vm._e(),(_vm.loading)?_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}):_vm._e()],1)]),(['mobile', 'tablet'].includes(_vm.breakpoint))?_c('MobileGallery',{attrs:{"color":_vm.color,"loading":_vm.loading,"updateMainPhoto":_vm.updateMainPhoto,"photosList":_vm.photosList}}):_vm._e(),_c('article',{style:({
        position: 'relative',
        paddingLeft: '30px',
        paddingBottom: '50px',
        width: '100%'
      })},[_c('div',[_c('div',{style:({ display: 'flex', flexDirection: 'column', marginTop: '14px' })},[_c('div',{style:({ color: _vm.color[8], width: '140px' })},[_vm._v("Status")]),_c('div',{style:({
              color: _vm.color[8],
              fontSize: '1.5rem',

              fontWeight: 'bold',
              paddingRight: '12px'
            }),attrs:{"vio-status":""}},[_vm._v(" "+_vm._s(_vm.details.status)+" ")])]),_c('div',{style:({ display: 'flex', flexDirection: 'column', marginTop: '14px' })},[_c('div',{style:({ color: _vm.color[8], width: '140px' })},[_vm._v("Type")]),_c('div',{style:({
              color: _vm.color[8],
              fontSize: '1.5rem',

              fontWeight: 'bold',
              paddingRight: '12px'
            }),attrs:{"vio-type":""}},[_vm._v(" "+_vm._s(_vm.details.typeName)+" ")])]),_c('div',{style:({ display: 'flex', flexDirection: 'column', marginTop: '14px' })},[_c('div',{style:({ color: _vm.color[8], width: '140px' })},[_vm._v("Owner")]),_c('div',{style:({
              color: _vm.color[8],

              fontWeight: 'bold',
              paddingRight: '12px'
            }),attrs:{"vio-owner":""}},[_vm._v(" "+_vm._s(_vm.details.ownerName)+" ")])]),_c('div',{style:({ display: 'flex', flexDirection: 'column', marginTop: '14px' })},[_c('div',{style:({ color: _vm.color[8], width: '140px' })},[_vm._v("Address")]),_c('div',{style:({
              color: _vm.color[8],

              fontWeight: 'bold',
              paddingRight: '12px'
            }),attrs:{"vio-address":""}},[_vm._v(" "+_vm._s(_vm.details.address)+" ")])])]),(_vm.authUserType !== 'HomeOwner')?_c('div',{staticClass:"pt-2",style:({
          // position: 'absolute',
          width: '100%',
          bottom: 0,
          left: '0',
          textAlign: 'right'
        })},[(_vm.violation.isClosed == false)?_c('b-button',{style:({ margin: '4px' }),on:{"click":function($event){$event.preventDefault();return _vm.confirmClose(_vm.violation.violationID, true)}}},[_vm._v("Close Violation")]):_c('b-button',{style:({ margin: '4px' }),on:{"click":function($event){$event.preventDefault();return _vm.confirmClose(_vm.violation.violationID, false)}}},[_vm._v("Open Violation")]),(_vm.violation.isClosed == false)?_c('b-button',{style:({ margin: '4px' }),attrs:{"escalator":"","type":"is-primary"},on:{"click":_vm.escalate}},[_vm._v("Escalate")]):_vm._e()],1):_vm._e()])],1),_c('Escalator',{ref:"escalator",attrs:{"onComplete":_vm.stepAdded}}),_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }