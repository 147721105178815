var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tile is-parent is-12"},[_c('article',{staticClass:"card",style:({
      padding: '1rem',
      width: '100%'
    })},[_vm._m(0),_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"card",style:({
        position: 'relative',
        padding: '1rem',
        boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)',
        marginBottom: '12px'
      }),attrs:{"vio-step":""}},[_c('div',{style:({
          position: 'absolute',
          top: '12px',
          right: '12px',
          color: _vm.color[8]
        }),attrs:{"vio-step-date":""}},[_vm._v(" "+_vm._s(step.observationDate)+" ")]),_c('b-tag',{attrs:{"size":"is-large","vio-step-count":""}},[_vm._v("Step "+_vm._s(step.count))]),_c('b-tag',{style:({ marginLeft: '12px' }),attrs:{"size":"is-large","vio-step-name":""}},[_vm._v(_vm._s(step.actionName))]),_c('div',{style:({ padding: '20px 0 20px 10px' })},[_c('div',{staticClass:"columns",style:({ display: 'flex' })},[_c('div',{staticClass:"column is-2",style:({ color: _vm.color[8] })},[_vm._v("Observed by:")]),_c('div',{staticClass:"column is-9",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" "+_vm._s(step.observedBy)+" ")])]),_c('div',{staticClass:"columns",style:({ display: 'flex' })},[_c('div',{staticClass:"column is-2",style:({ color: _vm.color[8] })},[_vm._v("Description:")]),(step.description.length <= 200)?_c('div',{staticClass:"column is-9",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" "+_vm._s(step.description)+" ")]):_vm._e(),(step.description.length > 200)?_c('div',{staticClass:"column is-9",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" "+_vm._s(step.description.slice(1, 200))),(step.description.length > 200)?_c('span',{staticClass:"pt-5 pb-3 pl-3"},[_c('b-button',{staticClass:"p-2 font-bold button is-small is-outline is-rounded",staticStyle:{"height":"20px"},attrs:{"title":"click to expand/collapse the description"},on:{"click":function($event){$event.preventDefault();return _vm.handleEllipsis()}}},[_vm._v(_vm._s(("" + (_vm.showEllipsis === false ? 'Read More...' : 'Read Less...'))))])],1):_vm._e(),(step.description.length > 200 && _vm.showEllipsis === true)?_c('span',{staticClass:"column is-9 pl-2",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(_vm._s(step.description.slice(200, step.description.length)))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"property-photo-gallery",style:({
          position: 'relative',
          width: '100%',
          height: '80%',
          paddingRight: '10px',
          paddingLeft: '6px'
        })},_vm._l((step.photos),function(photo,index){return _c('figure',{key:index,style:({
            position: 'relative',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '4px',
            height: '140px',
            width: '140px',
            overflow: 'hidden',
            borderRadius: '6px',
            boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
          }),attrs:{"vio-step-photo":"","tabindex":"0","aria-label":'photo ' + (index + 1)},on:{"click":function($event){$event.preventDefault();return _vm.openPhotoModal(photo)}}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{style:({
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                flexShrink: '0',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }),attrs:{"src":photo.content}})])],1)}),0),(step && step.violationLetters && step.violationLetters.length > 0)?_c('div',[_c('label',{staticClass:"label"},[_vm._v("Violation Letter(s)")])]):_vm._e(),_vm._l((step.violationLetters),function(letter,index){return _c('div',{key:index,staticClass:"card",style:({
          position: 'relative',
          padding: '1rem',
          boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)',
          marginBottom: '12px'
        }),attrs:{"aria-label":"Violation Letters"}},[_c('button',{staticClass:"button is-link is-light",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.getDocumentFile(letter.violationStepDocumentID)}}},[_vm._v(" "+_vm._s("View Letter")+" ")])])}),_c('b-modal',{style:({ zIndex: 999 }),model:{value:(_vm.photoModal.open),callback:function ($$v) {_vm.$set(_vm.photoModal, "open", $$v)},expression:"photoModal.open"}},[_c('p',{staticClass:"image is-4by3"},[_c('img',{attrs:{"src":_vm.photoModal.src}})])])],2)})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"level"},[_c('span',{staticClass:"title level-left"},[_vm._v("History")])])}]

export { render, staticRenderFns }