import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'
import { getString } from '@/utilities/get/string'
import parseAddress from '@/utilities/address/parse'
import { parseDate } from '@/utilities/Date/parse'
import { getV } from '@/services/ViolationIssue/Violation/Get'
import { getVOccurrenceList } from '@/services/ViolationIssue/ViolationOccurrence/List'
import { updateV } from '@/services/ViolationIssue/Violation/Put'
import { listSteps } from '@/services/ViolationIssue/ViolationStep/List'
import { getMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/GetMultiple'

export const methods = {
  getId() {
    if (typeof this.propId === 'number') {
      return this.propId
    }

    return _get(this, ['$route', 'params', 'id'], 0)
  },

  async stepAdded() {
    console.debug('in step added...')
    const fromRoute = _get(this, ['$route', 'path'], '')

    if (fromRoute.indexOf('violationDetails') !== -1) {
      console.debug('in reRender')
      this.reRender()
    } else {
      await this.getSteps({
        id: this.getId()
      })

      await this.refresh()
    }
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  async getSteps({ id }) {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    this.hoaID = hoaID

    var { successful, message, steps } = await listSteps({
      params: {
        hoaID,
        violationID: id
      }
    })
    if (!successful) {
      notifyProblem(message)
      return false
    }

    console.debug('steps=' + JSON.stringify(steps))

    const occurrences = this.occurrences
    //const photosList = []

    this.steps = steps
      .map((step, index) => {
        const occurrence = _get(step, 'violationOccurrence', {})

        let photosMeta = []

        /* get the photos meta data from the corresponding occurrence */
        for (let a = 0; a < occurrences.length; a++) {
          const o = occurrences[a]

          try {
            if (
              o.violationOccurrenceID === step.violationOccurrenceID &&
              Array.isArray(o.violationOccurrencePhotos)
            ) {
              photosMeta = o.violationOccurrencePhotos
              break
            }
          } catch (x) {
            console.error(x)
          }
        }

        const observationDate = getString(occurrence, 'observationDate', '')
        let observationDateParsed = parseDate(observationDate, 'LL')

        return {
          count: index + 1,
          actionName: getString(step, ['violationAction', 'name'], ''),
          observedBy: getString(occurrence, 'observedBy', ''),
          observationDate: observationDateParsed,
          description: getString(occurrence, 'description', ''),
          photosMeta,
          photos: []
        }
      })
      .reverse()

    this.$forceUpdate()

    return true
  },

  async lazyLoadMoreImages() {
    console.debug('in lazyLoadMoreImages()...')
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    let counter = 0

    const photosList = []

    for (let a = 0; a < this.steps.length; a++) {
      const { photosMeta } = this.steps[a]

      console.debug('counter=' + counter)

      if (photosMeta) {
        const { photos } = await getMultiplePhotos({
          hoaID: this.hoaID,
          paramsList: photosMeta.map(photo => {
            return {
              violationOccurrencePhotoID: _get(photo, ['violationOccurrencePhotoID'], ''),
              asBase64: true,
              scaleToWidth: 400
            }
          })
        })

        if (counter <= this.steps.length) {
          console.debug('photos===' + JSON.stringify(photos))
          photosList.push(...photos)
          this.mainPhoto = _get(photosList, [0, 'content'], '')
        }

        counter = counter + 1
      }
    }

    this.photosList.push(...photosList)

    loadingComponent.close()
  },

  async updateImages(index) {
    console.debug('updateImages=' + index)
  },

  async escalate() {
    const id = this.getId()

    this.$refs.escalator.open({
      id
    })
  },

  async getVDetails() {
    const id = this.getId()

    const { successful, message, details: vDetails } = await getV({
      id
    })
    if (!successful) {
      notifyProblem(message)

      return {
        vDetails: null
      }
    }

    this.violation = vDetails

    const isClosed = _get(vDetails, ['isClosed'], null)
    let status = 'Indeterminable'
    if (isClosed === true) {
      status = 'Closed'
    } else if (isClosed === false) {
      status = 'Open'
    }

    this.details = {
      ownerName: _get(vDetails, ['owner', 'name'], ''),
      address: parseAddress({
        address: _get(vDetails, ['owner', 'unit', 'address'], '')
      }),
      typeName: _get(vDetails, ['violationType', 'name'], ''),
      status
    }

    return {
      vDetails
    }
  },

  confirmClose(id, isClosed) {
    const message = isClosed
      ? 'Please confirm below, that you wish to close the violation and mark it as resolved. A <b>Violation Resolved Notice</b> will be sent to the homeowner.'
      : 'Please confirm below, that you wish to re-open the violation and mark it as un-resolved.'

    const title = isClosed ? 'Closure Confirmation' : 'Re-open Confirmation'
    const confirm = isClosed ? 'Close Violation' : 'Re-open Violation'

    this.$buefy.dialog.confirm({
      title: title,
      message: message,
      confirmText: confirm,
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.modifyStatus({ isClosed })
    })
  },

  async getOccurrences({ vDetails }) {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const violationID = _get(vDetails, ['violationID'], null)

    const { list, successful, message } = await getVOccurrenceList({
      json: {
        hoaID,
        violationID
      }
    })
    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return {
        successful: false
      }
    }
    //
    this.occurrences = list
    this.occurrence = list[0]

    return {
      successful: true
    }
  },

  async refresh() {
    this.loading = true

    const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)
    this.authUserType = authUserType
    console.log({
      authUserType
    })

    const { vDetails } = await this.getVDetails()
    if (vDetails === null) {
      this.loading = false
      return
    }

    const { successful } = await this.getOccurrences({
      vDetails
    })
    if (!successful) {
      this.loading = false
      return
    }

    const id = this.getId()
    await this.getSteps({
      id
    })

    this.loading = false

    this.lazyLoadMoreImages()
  },

  async modifyStatus({ isClosed }) {
    const { successful, message } = await updateV({
      json: {
        violationID: this.violation.violationID,
        ownerID: this.violation.ownerID,
        hoaID: this.violation.hoaID,
        violationTypeID: this.violation.violationTypeID,
        violationEscalationID: this.violation.violationEscalationID,
        isClosed,
        createdDate: this.violation.createdDate,
        modifiedDate: this.violation.modifiedDate
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    this.refresh()
  },

  async updateMainPhoto(index) {
    console.debug('updateMainPhoto...')
    const photosList = this.photosList

    if (Array.isArray(photosList) && photosList.length >= 1) {
      this.mainPhoto = _get(photosList, [index, 'content'], '')
    }
  }
}
