<template>
  <section
    vio-details
    :style="{
      display: 'flex'
    }"
  >
    <div
      :style="{
        width: '20%',
        marginRight: '1%'
      }"
      v-if="breakpoint === 'desktop'"
    >
      <DesktopGallery
        :color="color"
        :loading="loading"
        :updateMainPhoto="updateMainPhoto"
        :photosList="photosList"
      />
    </div>

    <div
      class="card"
      :style="{
        display: 'flex',
        width: '100%',
        padding: '1rem',

        ...(breakpoint === 'desktop'
          ? {}
          : {
              flexDirection: 'column'
            })
      }"
    >
      <!-- main photo -->
      <article :style="{ textAlign: 'center' }">
        <figure
          tabindex="0"
          aria-label="main photo"
          :style="{
            position: 'relative',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',

            margin: '4px',

            height: '380px',
            width: '380px',
            maxWidth: '100%',

            overflow: 'hidden',
            borderRadius: '6px',

            boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
          }"
        >
          <transition name="fade">
            <img
              vio-main-photo
              v-if="mainPhoto && mainPhoto.length > 0"
              :src="mainPhoto"
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',

                flexShrink: '0',
                maxWidth: '100%',
                maxHeight: '100%',
                height: '100%',
                width: '100%',
                objectFit: 'contain'
              }"
            />
          </transition>

          <div v-if="mainPhoto && mainPhoto.length === 0" :style="{ color: color[8] }">
            No photos were added
          </div>
          <b-loading :is-full-page="false" v-model="loading" v-if="loading"></b-loading>
        </figure>
      </article>

      <MobileGallery
        v-if="['mobile', 'tablet'].includes(breakpoint)"
        :color="color"
        :loading="loading"
        :updateMainPhoto="updateMainPhoto"
        :photosList="photosList"
      />

      <!-- summary -->
      <article
        :style="{
          position: 'relative',
          paddingLeft: '30px',
          paddingBottom: '50px',
          width: '100%'
        }"
      >
        <div>
          <div :style="{ display: 'flex', flexDirection: 'column', marginTop: '14px' }">
            <div :style="{ color: color[8], width: '140px' }">Status</div>
            <div
              vio-status
              :style="{
                color: color[8],
                fontSize: '1.5rem',

                fontWeight: 'bold',
                paddingRight: '12px'
              }"
            >
              {{ details.status }}
            </div>
          </div>
          <div :style="{ display: 'flex', flexDirection: 'column', marginTop: '14px' }">
            <div :style="{ color: color[8], width: '140px' }">Type</div>
            <div
              vio-type
              :style="{
                color: color[8],
                fontSize: '1.5rem',

                fontWeight: 'bold',
                paddingRight: '12px'
              }"
            >
              {{ details.typeName }}
            </div>
          </div>
          <div :style="{ display: 'flex', flexDirection: 'column', marginTop: '14px' }">
            <div :style="{ color: color[8], width: '140px' }">Owner</div>
            <div
              vio-owner
              :style="{
                color: color[8],

                fontWeight: 'bold',
                paddingRight: '12px'
              }"
            >
              {{ details.ownerName }}
            </div>
          </div>
          <div :style="{ display: 'flex', flexDirection: 'column', marginTop: '14px' }">
            <div :style="{ color: color[8], width: '140px' }">Address</div>
            <div
              vio-address
              :style="{
                color: color[8],

                fontWeight: 'bold',
                paddingRight: '12px'
              }"
            >
              {{ details.address }}
            </div>
          </div>
        </div>
        <div
          class="pt-2"
          v-if="authUserType !== 'HomeOwner'"
          :style="{
            // position: 'absolute',
            width: '100%',
            bottom: 0,
            left: '0',
            textAlign: 'right'
          }"
        >
          <b-button
            v-if="violation.isClosed == false"
            @click.prevent="confirmClose(violation.violationID, true)"
            :style="{ margin: '4px' }"
            >Close Violation</b-button
          >
          <b-button
            v-else
            @click.prevent="confirmClose(violation.violationID, false)"
            :style="{ margin: '4px' }"
            >Open Violation</b-button
          >
          <b-button
            v-if="violation.isClosed == false"
            escalator
            type="is-primary"
            @click="escalate"
            :style="{ margin: '4px' }"
            >Escalate</b-button
          >
        </div>
      </article>
    </div>

    <Escalator ref="escalator" :onComplete="stepAdded" />

    <b-loading :is-full-page="true" v-model="loading"></b-loading>
  </section>
</template>

<script>
import Escalator from '@/pages/Management/violations/Escalator'

import DesktopGallery from './components/DesktopGallery'
import MobileGallery from './components/MobileGallery'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
import { watch } from './keys/watch'

export default {
  components: {
    Escalator,
    DesktopGallery,
    MobileGallery
  },

  computed,
  data,
  methods,
  mounted,
  props,
  watch,

  i18n: {
    messages: {
      en: {
        violationDetails: {
          title: 'Violation Details'
        }
      }
    }
  }
}
</script>
