/*
  import { violationStepDocumentStore } from '@/services/ViolationIssue/ViolationStepDocument/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await violationStepDocumentStore.dispatch ('getViolationStepDocumentList', {
      hoaID,
      violationOccurrenceID,
      violationStepID,
      includeDocumentsAsBase64,
      includeDocumentAsHtml
    });
  */

  async getViolationStepDocumentList({}, { params }) {
    console.log('in getViolationStepDocumentList...' + JSON.stringify(params))
    try {
      let path = ''

      if (params.hoaID) {
        path = `?hoaID=${params.hoaID}`
      }

      if (params.violationOccurrenceID) {
        path = path + `&violationOccurrenceID=${params.violationOccurrenceID}`
      }

      if (params.violationStepID) {
        path = path + `&violationStepID=${params.violationStepID}`
      }

      if (params.includeDocumentsAsBase64) {
        path = path + `&includeDocumentsAsBase64=${params.includeDocumentsAsBase64}`
      }

      if (params.includeDocumentAsHtml) {
        path = path + `&includeDocumentAsHtml=${params.includeDocumentAsHtml}`
      }

      const results = await kms.get(`/ViolationIssue/ViolationStepDocument/List${path}`)

      if (isDebug == true)
        console.debug('getViolationStepDocumentList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Violation Document list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getFile({ dispatch }, { params, done }) {
    const path = `/ViolationIssue/ViolationStepDocument/GetFile`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved this document.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving this document.')
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const violationStepDocumentStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
