<template>
  <div id="v-escalator" vio-escalator>
    <Modal
      :toggle.sync="isOpen"
      :styles="{
        modalContent: { height: '600px' },
        modal: { maxHeight: '90vh' }
      }"
      :loading="loading"
    >
      <template v-slot:header>Escalate Violation</template>

      <b-steps v-model="page" size="is-small" :has-navigation="false">
        <b-step-item label="Action Select" ve-action-select>
          <!-- owner -->
          <div
            :style="{
              display: 'flex',
              padding: '3px',
              alignItems: 'center'
            }"
          >
            <div
              :style="{
                display: 'inline-block',
                marginRight: '12px',
                flexBasis: '200px',
                flexGrow: 0,
                flexShrink: 0
              }"
            >
              <b-tag :style="{ fontWeight: 'bold' }">Owner</b-tag>
            </div>
            <div>{{ details.ownerName }}</div>
          </div>

          <!-- address -->
          <div
            :style="{
              display: 'flex',
              padding: '3px',
              alignItems: 'center'
            }"
          >
            <div
              :style="{
                display: 'inline-block',
                marginRight: '12px',
                flexBasis: '200px',
                flexGrow: 0,
                flexShrink: 0
              }"
            >
              <b-tag :style="{ fontWeight: 'bold' }">Address</b-tag>
            </div>
            <div>{{ details.ownerAddress }}</div>
          </div>

          <!-- type -->
          <div
            :style="{
              display: 'flex',
              padding: '3px',
              alignItems: 'center'
            }"
          >
            <div
              :style="{
                display: 'inline-block',
                marginRight: '12px',
                flexBasis: '200px',
                flexGrow: 0,
                flexShrink: 0
              }"
            >
              <b-tag :style="{ fontWeight: 'bold' }">Type</b-tag>
            </div>
            <div>{{ details.type }}</div>
          </div>

          <!-- steps taken -->
          <div :style="{ borderTop: `1px solid ${color[9]}` }">
            <div
              :style="{
                display: 'flex',
                padding: '3px',
                alignItems: 'center'
              }"
            >
              <div
                :style="{
                  display: 'inline-block',
                  marginRight: '12px',
                  flexBasis: '200px',
                  flexGrow: 0,
                  flexShrink: 0
                }"
              >
                <b-tag :style="{ fontWeight: 'bold' }">Steps Taken</b-tag>
              </div>
            </div>

            <div
              v-for="(step, index) in details.steps"
              :key="index"
              class="card rounded shadow bg-white"
              :style="{
                position: 'relative',
                marginBottom: '10px',
                padding: '12px',
                border: '1px solid #f4f1f1'
              }"
            >
              <div>
                <div>Action: {{ step.actionName }}</div>
                <div>Action Description: {{ step.actionDescription }}</div>
                <div>Observed By: {{ step.observedBy }}</div>
                <div>Observation Description: {{ step.observationDescription }}</div>
              </div>
              <div>Observation Date: {{ step.observationDate }}</div>
            </div>
          </div>

          <!-- next step -->
          <div :style="{ borderTop: `1px solid ${color[9]}` }"></div>
          <div
            :style="{
              display: 'flex',
              padding: '3px',
              alignItems: 'center'
            }"
          >
            <div
              :style="{
                display: 'inline-block',
                marginRight: '12px',
                flexBasis: '200px',
                flexGrow: 0,
                flexShrink: 0
              }"
            >
              <b-tag :style="{ fontWeight: 'bold' }">Next Step</b-tag>
            </div>
          </div>

          <div
            v-for="(action, index) in allActions"
            :key="index"
            class="card rounded shadow bg-white"
            :style="{
              display: 'flex',
              justifyItems: 'right',
              justifyContent: 'space-between',

              marginBottom: '10px',
              border: '1px solid #f4f1f1',
              padding: '12px'
            }"
          >
            <p :style="{ padding: '8px 0' }">{{ action.description }}</p>
            <button class="button is-primary is-rounded" @click="select(action)" ve-action-button>
              {{ action.name }}
            </button>
          </div>
        </b-step-item>

        <b-step-item label="Details" ve-details>
          <div :style="{ width: '70%', margin: '0 auto 50px' }">
            <b-field label="Observed By">
              <b-input
                ve-observed-by
                maxlength="255"
                aria-required="true"
                type="text"
                v-model="observedBy"
              ></b-input>
            </b-field>

            <b-field label="Observed On">
              <DatePicker
                ve-observation-date
                ref="observationDateComponent"
                v-model="observationDate"
                ariaLabel="date"
              />
            </b-field>

            <b-field label="Description" :style="{ marginTop: '20px' }">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="description"
                ve-description
              ></b-input>
            </b-field>

            <b-field label="Pictures (optional)" :style="{ marginTop: '20px' }">
              <ImageUploader :onChange="imagesUpdated" ve-image-uploader />
            </b-field>

            <div
              :style="{
                padding: '30px 0',
                textAlign: 'right'
              }"
            >
              <b-button
                type="is-primary"
                size="is-medium"
                @click="checkDetails"
                ve-details-next-button
                >Next</b-button
              >
            </div>
          </div>
        </b-step-item>

        <b-step-item label="Confirmation" ve-confirmation>
          <div :style="{ width: '70%', margin: '0 auto' }">
            <p :style="{ fontSize: '1.4rem' }">
              To proceed, please review the details and then press <b>Confirm</b>.
            </p>

            <div :style="{ padding: '30px 0' }">
              <div
                :style="{
                  borderTop: `1px solid ${color[9]}`,
                  margin: '5px 0'
                }"
              ></div>

              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Action</div>
                <div :style="Object.assign({}, styles.listItem)">{{ selected.name }}</div>
              </div>

              <div
                :style="{
                  borderTop: `1px solid ${color[9]}`,
                  margin: '5px 0'
                }"
              ></div>

              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Owner</div>
                <div :style="Object.assign({}, styles.listItem)">{{ details.ownerName }}</div>
              </div>
              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Address</div>
                <div :style="Object.assign({}, styles.listItem)">{{ details.ownerAddress }}</div>
              </div>

              <div
                :style="{
                  borderTop: `1px solid ${color[9]}`,
                  margin: '5px 0'
                }"
              ></div>

              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Observed By</div>
                <div :style="Object.assign({}, styles.listItem)">{{ observedBy }}</div>
              </div>
              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Observation Date</div>
                <div :style="Object.assign({}, styles.listItem)">{{ observationDateParsed }}</div>
              </div>
              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Description</div>
                <div :style="Object.assign({}, styles.listItem)">{{ description }}</div>
              </div>
              <div :style="Object.assign({}, styles.listRow)">
                <div :style="Object.assign({}, styles.listLabel)">Pictures</div>
                <div :style="Object.assign({}, styles.listItem)">
                  <figure
                    v-for="(preview, index) in previews"
                    :key="index"
                    tabindex="0"
                    :aria-label="'photo ' + (index + 1)"
                    :style="{
                      position: 'relative',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      margin: '4px',

                      width: '70px',
                      height: '70px',

                      overflow: 'hidden',
                      borderRadius: '6px',

                      boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
                    }"
                  >
                    <transition name="fade">
                      <img
                        :src="preview"
                        :style="{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translateX(-50%) translateY(-50%)',

                          flexShrink: '0',
                          maxWidth: '100%',
                          maxHeight: '100%',
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain'
                        }"
                      />
                    </transition>
                  </figure>
                </div>
              </div>
            </div>

            <div style="display: flex; flex-direction: row; justify-content: end">
              <b-button
                type="is-primary"
                size="is-medium"
                :style="{ marginRight: '20px' }"
                @click="preview"
                >Preview</b-button
              >

              <b-button
                ve-confirm-button
                type="is-primary"
                size="is-medium"
                :style="{ float: 'right' }"
                @click="confirm"
                >Confirm</b-button
              >
            </div>

            <div :style="{ height: '70px' }"></div>
          </div>
        </b-step-item>
      </b-steps>
    </Modal>
  </div>
</template>

<style lang="scss">
#v-escalator {
  .b-steps .steps + .step-content {
    padding-top: 30px !important;
  }

  nav.steps {
    position: absolute;
    top: 0;
    left: 0;

    height: 70px;
    width: 100%;
    padding-top: 15px;

    border-bottom: 1px solid rgb(238, 238, 238);
  }

  section.step-content {
    position: absolute;
    top: 70px;
    left: 0;

    height: calc(100% - 70px);
    width: 100%;

    overflow-y: scroll;
  }
}
</style>

<script>
import Modal from '@/components/Modal'
import DatePicker from '@/components/inputs/DatePicker'
import ImageUploader from '@/components/Fields/ImageUploader'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'

export default {
  components: {
    DatePicker,
    ImageUploader,
    Modal
  },

  computed,
  data,
  methods,
  props
}
</script>
