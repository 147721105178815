<template>
  <PageContent role="region" violation-details>
    <div class="level">
      <div class="level-left level-item title">
        {{ $t('violationDetails.title') + ` ID: ${violationID}` }}
      </div>
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>
        <div class="level-item">
          <b-button label="Back to Violations" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>
    <section class="section card">
      <LinksMenu
        aria-label="account settings tabs"
        :tabs="tabs"
        prefix="violation."
        :style="{
          ...(breakpoint === 'desktop'
            ? {
                display: 'flex'
              }
            : {
                display: 'block'
              })
        }"
        :styles="{
          ul: {
            width: '140px'
          }
        }"
        :params="{
          id: $route.params.id
        }"
      >
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </section>
  </PageContent>
</template>

<script>
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import PageContent from '@/containers/PageContent'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

import LinksMenu from '@/components/menus/Links'

export default {
  components: {
    SlideTransition,
    PageContent,
    LinksMenu
  },

  data,
  methods,
  watch,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      breakpoint: state => state.win.breakpoint
    }),

    debug() {
      return this.unit
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: {
        violationDetails: {
          title: 'Violation'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
