/*
  example:
    import { StorePhoto } from '@/services/ViolationIssue/ViolationOccurrencePhoto/StorePhoto';

    const { successful, documentId } = await StorePhoto ({
      hoaID,
      photo
    });
    if (!successful) {

    }
*/

/*
  example response:
    containerID: 24
    contentType: "image/jpeg"
    createdDate: "2021-08-28T01:00:43.9473631+00:00"
    deletedDate: null
    description: ""
    documentId: 1168
    fileSize: 3370
    filename: "3.jpeg"
    modifiedDate: "2021-08-28T01:00:43.9473631+00:00"
    version: 1
*/
import kms from '@/services/kms'
import _get from 'lodash/get'
//
export async function StorePhoto({ hoaID, photo }) {
  try {
    const path = `/ViolationIssue/ViolationOccurrencePhoto/StorePhoto?hoaID=${hoaID}`

    console.log({
      hoaID,
      photo
    })

    const formData = new FormData()
    formData.append('file', photo)
    const results = await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    console.log({ results })

    const documentId = _get(results, 'documentId', null)

    return {
      successful: true,
      photo,
      documentId
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    photo,
    documentId: null
  }
}
