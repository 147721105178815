/*
  import { getMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/GetMultiple';

  const { photos, problems } = await getMultiplePhotos ({
    paramsList: []
  });
  if (!successful) {

  }
*/

import { getPhoto } from '../GetFile'

export async function getMultiplePhotos({ paramsList }) {
  const results = await Promise.all(
    paramsList.map(params => {
      return getPhoto({
        params
      })
    })
  )

  const problems = []
  const retrievals = []

  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful } = result

    if (successful === true) {
      retrievals.push({
        content: result.photo
      })
    } else {
      problems.push(result)
    }
  }

  return {
    photos: retrievals,
    problems
  }
}
