/*
  import { listSteps } from '@/services/ViolationIssue/ViolationStep/List';

  // for example:
    const { successful, message, steps } = await listSteps ({
      params: {
        hoaID,
        violationID
      }
    });
    if (!successful) {
      notifyProblem ('A problem occurred and the violation could not be reported');
      return;
    }

*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function listSteps({ kms: kms_ = kms, params } = {}) {
  let message = `The list of steps could not be retrieved.`

  try {
    const result = await kms_.get(`/ViolationIssue/ViolationStep/List`, {
      params
    })

    let steps = _get(result, 'results', [])
    if (!Array.isArray(steps)) {
      steps = []
    }

    return {
      successful: true,
      message: null,
      steps
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}
